
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditBuilding from "@/components/reusable/claims/house-insurance/building/EditBuilding.vue";

export default defineComponent({
  name: "broker-motor-insurance-claims-edit",
  components: { EditBuilding },
  props: ["publicId"],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Claims",
        "House Insurance Claim",
        "Building"
      ]);
    });
  }
});
